<template>
  <section class="sponsors">
    <div class="sponsors__content">
      <h1 class="sponsors__title">
          2024 Sponsors
      </h1>
      <div class="sponsors__logos">
        <a href="https://theseg.com/" target="_blank">
          <div class="sponsors__item single">
            <div class="sponsors__logo-container nobg uncommon">
              <img src="../assets/sponsors/seg.png" alt="The Special Equities Group" class="logo">
            </div>
            <h2 class="sponsors__name">The Special Equities Group</h2>
          </div>
        </a>
      </div>
      <div class="sponsors__logos">
        <a href="https://www.moneychannelnyc.com/" target="_blank">
          <div class="sponsors__item single">
            <div class="sponsors__logo-container nobg">
              <img src="../assets/sponsors/moneychannel.png" alt="The Money Channel" class="full-logo">
            </div>
            <h2 class="sponsors__name">The Money Channel</h2>
          </div>
        </a>
        <a href="https://www.allianceg.com/" target="_blank">
          <div class="sponsors__item single">
            <div class="sponsors__logo-container nobg">
              <img src="../assets/sponsors/aglobalp.png" alt="Alliance Global Partners" class="full-logo">
            </div>
            <h2 class="sponsors__name">Alliance Global Partners</h2>
          </div>
        </a>
        <a href="https://www.roth.com/" target="_blank">
          <div class="sponsors__item single">
            <div class="sponsors__logo-container nobg">
              <img src="../assets/sponsors/roth.png" alt="Roth Capital Partners" class="full-logo">
            </div>
            <h2 class="sponsors__name">Roth Capital Partners</h2>
          </div>
        </a>
        <a href="https://www.investpr.org/" target="_blank">
          <div class="sponsors__item single">
            <div class="sponsors__logo-container nobg">
              <img src="../assets/sponsors/investpr.svg" alt="Invest Puerto Rico" class="logo">
            </div>
            <h2 class="sponsors__name">Invest Puerto Rico</h2>
          </div>
        </a>
        <a href="https://impeller.investpr.org/offerings" target="_blank">
          <div class="sponsors__item single">
            <div class="sponsors__logo-container nobg">
              <img src="../assets/sponsors/impeller.png" alt="Impeller" class="logo">
            </div>
            <h2 class="sponsors__name">Impeller</h2>
          </div>
        </a>
        <a href="https://www.uncommonentrepreneurs.com/" target="_blank">
          <div class="sponsors__item single">
            <div class="sponsors__logo-container nobg">
              <img src="../assets/sponsors/uncommon.png" alt="Uncommon Entrepreneurs" class="full-logo">
            </div>
            <h2 class="sponsors__name">Uncommon EntrePReneurs</h2>
          </div>
        </a>
        <a href="https://www.gemxx.com/" target="_blank">
          <div class="sponsors__item single">
            <div class="sponsors__logo-container nobg">
              <img src="../assets/sponsors/gx.png" alt="GEMXX Corporation" class="logo">
            </div>
            <h2 class="sponsors__name">GEMXX Corporation</h2>
          </div>
        </a>
        <a href="https://www.m2compliance.com/" target="_blank">
          <div class="sponsors__item single">
            <div class="sponsors__logo-container nobg">
              <img src="../assets/sponsors/m2.png" alt="M2 Compliance" class="logo">
            </div>
            <h2 class="sponsors__name">M2 Compliance</h2>
          </div>
        </a>
        <a href="https://www.maximgrp.com/" target="_blank">
          <div class="sponsors__item single">
            <div class="sponsors__logo-container nobg">
              <img src="../assets/sponsors/maxim.png" alt="Maxim" class="full-logo">
            </div>
            <h2 class="sponsors__name">Maxim Group</h2>
          </div>
        </a>
        <a href="https://seanetco.com/" target="_blank">
          <div class="sponsors__item single">
            <div class="sponsors__logo-container nobg">
              <img src="../assets/sponsors/seanet.png" alt="SeaNet Co." class="logo">
            </div>
            <h2 class="sponsors__name">SeaNet</h2>
          </div>
        </a>
        <a href="https://www.mzgroup.com/" target="_blank">
          <div class="sponsors__item single">
            <div class="sponsors__logo-container nobg">
              <img src="../assets/sponsors/mz.png" alt="MZ Group" class="logo">
            </div>
            <h2 class="sponsors__name">MZ Group</h2>
          </div>
        </a>
        <a href="https://www.cocotaps.com/" target="_blank">
          <div class="sponsors__item single">
            <div class="sponsors__logo-container nobg">
              <img src="../assets/sponsors/cocotaps.png" alt="CocoTaps Beverage Co." class="logo">
            </div>
            <h2 class="sponsors__name">CocoTaps Beverage Co.</h2>
          </div>
        </a>
        <a href="https://www.velocityclearingllc.com/" target="_blank">
          <div class="sponsors__item single">
            <div class="sponsors__logo-container nobg">
              <img src="../assets/sponsors/velocity.png" alt="Velocity Clearing" class="full-logo">
            </div>
            <h2 class="sponsors__name">Velocity Clearing</h2>
          </div>
        </a>
        <a href="https://www.guardiantrading.com/" target="_blank">
          <div class="sponsors__item single">
            <div class="sponsors__logo-container nobg">
              <img src="../assets/sponsors/guardian.png" alt="Guardian Trading" class="full-logo">
            </div>
            <h2 class="sponsors__name">Guardian Trading</h2>
          </div>
        </a>
        <a href="https://assurtrak.com/" target="_blank">
          <div class="sponsors__item single">
            <div class="sponsors__logo-container nobg">
              <img src="../assets/sponsors/assurtrak.png" alt="Assurtrak" class="full-logo">
            </div>
            <h2 class="sponsors__name">Assurtrak</h2>
          </div>
        </a>
        <a href="https://www.josephgunnar.com/" target="_blank">
          <div class="sponsors__item single">
            <div class="sponsors__logo-container nobg">
              <img src="../assets/sponsors/josephg.png" alt="Joseph Gunnar & Co LLC" class="full-logo">
            </div>
            <h2 class="sponsors__name">Joseph Gunnar & Co</h2>
          </div>
        </a>
        <a href="https://www.c6capllc.com/" target="_blank">
          <div class="sponsors__item single">
            <div class="sponsors__logo-container nobg">
              <img src="../assets/sponsors/c6.png" alt="C6 Capital LLC" class="logo">
            </div>
            <h2 class="sponsors__name">C6 Capital</h2>
          </div>
        </a>
        <a href="https://www.science-inc.com/" target="_blank">
          <div class="sponsors__item single">
            <div class="sponsors__logo-container nobg">
              <img src="../assets/sponsors/science.png" alt="Science" class="full-logo">
            </div>
            <h2 class="sponsors__name">Science</h2>
          </div>
        </a>
        <a href="https://parallel18.com/" target="_blank">
          <div class="sponsors__item single">
            <div class="sponsors__logo-container nobg">
              <img src="../assets/sponsors/parallel.png" alt="Parallel18" class="full-logo">
            </div>
            <h2 class="sponsors__name">Parallel18</h2>
          </div>
        </a>
      </div>
      <h1 class="sponsors__title media">
          Media Sponsors
      </h1>
      <div class="sponsors__logos">
        <a href="https://evmobility.com/" target="_blank">
          <div class="sponsors__item single">
            <div class="sponsors__logo-container nobg">
              <img src="../assets/sponsors/ev.png" alt="EV Mobility" class="full-logo">
            </div>
            <h2 class="sponsors__name">EV Mobility</h2>
          </div>
        </a>
        <a href="http://thestreet.com/crypto" target="_blank">
          <div class="sponsors__item single">
            <div class="sponsors__logo-container nobg">
              <img src="../assets/sponsors/streetcrypto.png" alt="TheStreet Crypto" class="full-logo">
            </div>
            <h2 class="sponsors__name">TheStreet Crypto</h2>
          </div>
        </a>
        <a href="https://microcaps.com/" target="_blank">
          <div class="sponsors__item single">
            <div class="sponsors__logo-container nobg">
              <img src="../assets/sponsors/microcaps.png" alt="Microcaps.com" class="full-logo">
            </div>
            <h2 class="sponsors__name">Microcaps.com</h2>
          </div>
        </a>
        <a href="https://factoreseconomicos.com/" target="_blank">
          <div class="sponsors__item single facteco">
            <div class="sponsors__logo-container nobg">
              <img src="../assets/sponsors/feconomicos.png" alt="Factores Económicos" class="full-logo">
            </div>
            <h2 class="sponsors__name smaller">Factores Económicos</h2>
          </div>
        </a>
      </div>
    </div>
  </section>
</template>

<style lang="scss" scoped>
  @import '@/styles/components/sponsors.scss';
</style>

<script>

export default {
  name: 'sponsors',
  data() {
    return {
    };
  },
};
</script>